<template>
  <main>
    <nav-bar class="sticky top-0 z-10" :title="page.title" />

    <div class="px-2 z-0 divide-y-8 divide-blue font-wsmedium lg:w-3/6 mx-auto">
      <div v-if="$apollo.queries.page.loading">Loading...</div>
      <div v-if="error">{{ error }}</div>
      <div
        class="content font-wsregular text-base leading-5 py-10"
        v-html="page.content"
      ></div>
    </div>

    <!-- <main class="container px-2 pt-24 mx-auto md:w-6/12"> -->
  </main>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { GET_PAGE_QUERY } from "../constants/graphql";


export default {
  name: "About",
  components: {
    NavBar,
  },
  methods: {
  },
  computed: {

  },
  data() {
    return {
      page: {
          title: "",
          content: ""
      },
      error: null,
    };
  },
  mounted() {
    // console.log(this.plotTags)
    //console.log(this.$route.params)
  },
  apollo: {
    page: {
      // graphql query
      query: GET_PAGE_QUERY,
      update(data) {
        return data.page;
      },
      variables() {
        // Use vue reactive properties here
        return {
          id: 'about',
        };
      },
      //  update: data => data.hello,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.content >>> p {
   margin-bottom: 20px;
}

.content >>> .wp-block-image {
  margin-bottom: 10px;
}

.content >>> .wp-block-image {
  background-color: #ffffff;
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.content >>> .wp-block-image img {
  filter: grayscale(100%) contrast(1) blur(0px);
  flex: 1 0 100%;
  height: 100%;
  max-width: 100%;
  mix-blend-mode: multiply;
  object-fit: cover;
  opacity: 1;
  position: relative;
  width: 100%;
}

.content >>> .wp-block-image::before {
  background-color: #001689;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  mix-blend-mode: screen;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.content >>> .no-filter {
  background-color: none;
}

.content >>> .no-filter img {
  filter: none;
  mix-blend-mode: normal;
}

.content >>> .no-filter::before {
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
}

</style>
